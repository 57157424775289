/* eslint-disable import/no-cycle */
import React, { useState } from 'react';
import styled from '@emotion/styled';
import SabineJPG from '@assets/images/sabine.jpeg';
import OchsJPG from '@assets/images/ochs.jpeg';
import TrainingJPG from '@assets/images/training.jpeg';
import RöverJPG from '@assets/images/röver.jpeg';
import KellerJPG from '@assets/images/keller.jpg';
import YoungJPG from '@assets/images/young.jpg';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import Header from '~/components/molecules/Header/Header';
import FrontDeskImage from '~/components/organisms/FrontDeskImage/FrontDeskImage';
import Teaser from '~/components/atoms/Teaser/Teaser';
import Duo from '~/components/molecules/Duo/Duo';
import Image from '~/components/atoms/Image/Image';
import TrainingImage from '~/components/molecules/TrainingImage/TrainingImage';
import Contact from '~/components/atoms/Contact/Contact';
import Footer from '~/components/molecules/Footer/Footer';
import MobileCTAHeader from '~/components/molecules/MobileCTAHeader/MobileCTAHeader';
import MobileCTAFooter from '~/components/molecules/MobileCTAFooter/MobileCTAFooter';
import { ImageContainerHome } from '~/utils/styles/utils';
import { useTranslationContext } from '~/pages/index';

const HomePage: React.FC = () => {
  const [menu, setMenu] = useState(false);
  const t = useTranslationContext();
  const { i18n } = useTranslation();

  const clickHandler = () => {
    setMenu(!menu);
  };

  const menuHandler = () => {
    if (menu) {
      setMenu(false);
    }
  };

  return (
    <>
      <Container>
        <MobileCTAHeader />
        <Header clickHandle={clickHandler} />
        <FrontDeskImage shaded menu={menu} clickHandle={menuHandler} />
        <Teaser
          heading={t('TeaserHeading')}
          text={t('TeaserText')}
          whiteBackground
          small={false}
          link={i18n.language === 'de' ? '/praxis/' : '/en/practice/'}
          headingLevel={1}
        />
        <Heading>{t('Heading')}</Heading>
        <div
          style={{
            maxWidth: '550px',
            margin: '0 auto',
            paddingBottom: '4rem',
            paddingTop: '2rem',
          }}
        >
          <div
            className="wistia_responsive_padding"
            style={{ padding: '56.25% 0 0 0', position: 'relative' }}
          >
            <div
              className="wistia_responsive_wrapper"
              style={{
                height: '100%',
                left: '0',
                position: 'absolute',
                top: '0',
                width: '100%',
              }}
            >
              <div
                // eslint-disable-next-line no-secrets/no-secrets
                className="wistia_embed wistia_async_vw4oxt0ehz videoFoam=true"
                style={{ height: '100%', position: 'relative', width: '100%' }}
              >
                <div
                  className="wistia_swatch"
                  style={{
                    height: '100%',
                    left: '0',
                    opacity: '0',
                    overflow: 'hidden',
                    position: 'absolute',
                    top: '0',
                    transition: 'opacity 200ms',
                    width: '100%',
                  }}
                >
                  <img
                    src="https://fast.wistia.com/embed/medias/vw4oxt0ehz/swatch"
                    style={{
                      filter: 'blur(5px)',
                      height: '100%',
                      objectFit: 'contain',
                      width: '100%',
                    }}
                    alt=""
                    aria-hidden="true"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Duo>
          <ImageContainerHome>
            <Image src={SabineJPG} alt="Sabine" width={600} />
          </ImageContainerHome>
          <ImageContainerHome>
            <Image src={OchsJPG} alt="Ochs" width={600} />
          </ImageContainerHome>
        </Duo>
        <Teaser
          heading={t('NeurologyHeading')}
          text={t('NeurologyText')}
          small={false}
          link={i18n.language === 'de' ? '/neurologie/' : '/en/neurology/'}
          headingLevel={2}
          height="350px"
        />
        <Duo>
          <ImageContainerHome>
            <Image src={KellerJPG} alt="Keller" width={600} />
          </ImageContainerHome>
          <ImageContainerHome>
            <Teaser
              heading={t('NeuropsychologyHeading')}
              text={t('NeuropsychologyText')}
              small
              whiteBackground
              link={
                i18n.language === 'de'
                  ? '/neuropsychologie/'
                  : '/en/neuropsychology/'
              }
              headingLevel={3}
            />
          </ImageContainerHome>
        </Duo>
        <Duo>
          <ImageContainerHome>
            <Teaser
              heading={t('SportsHeading')}
              text={t('SportsText')}
              small
              whiteBackground={false}
              link={
                i18n.language === 'de'
                  ? '/sportmedizin-laktattest/'
                  : '/en/sports-medicine-lactatetest/'
              }
              headingLevel={3}
            />
          </ImageContainerHome>
          <ImageContainerHome>
            <Image src={TrainingJPG} alt="Trainingsraum" width={600} />
          </ImageContainerHome>
        </Duo>
        <Duo>
          <ImageContainerHome>
            <Image src={YoungJPG} alt="Dr. Young" width={600} />
          </ImageContainerHome>
          <ImageContainerHome>
            <Teaser
              heading={t('SleepHeading')}
              text={t('SleepText')}
              small
              whiteBackground={false}
              link={
                i18n.language === 'de'
                  ? '/schlafmedizin-muenchen/'
                  : '/en/sleep-medicine-munich/'
              }
              headingLevel={3}
            />
          </ImageContainerHome>
        </Duo>
        <Duo>
          <ImageContainerHome>
            <Teaser
              heading={t('PsychiatryHeading')}
              text={t('PsychiatryText')}
              small
              whiteBackground={false}
              link={
                i18n.language === 'de' ? '/psychiatrie/' : '/en/psychiatry/'
              }
              headingLevel={3}
            />
          </ImageContainerHome>
          <ImageContainerHome>
            <Image src={RöverJPG} alt="Röver" width={600} />
          </ImageContainerHome>
        </Duo>
        <TrainingImage />
        <Contact />
        <Footer />
        <MobileCTAFooter />
      </Container>
    </>
  );
};

const Container = styled.div`
  margin-left: auto;
  margin-right: auto;

  @media (min-width: 1200px) {
    width: 1250px;
  }
`;

const Heading = styled.h2`
  @media (min-width: 1200px) {
    max-width: 800px;
  }

  margin-left: auto;
  margin-right: auto;
  font-weight: normal;
  margin-top: 1rem;
  margin-bottom: 2rem;
  text-align: center;
  color: black;
  font-size: 1.5rem;
  line-height: 1.5;
`;

export default HomePage;
