/* eslint-disable react/no-danger */
import React from 'react';
import styled from '@emotion/styled';

type Props = { heading: string; text: string };

const CovidBox: React.FC<Props> = ({ heading, text }) => {
  function createMarkup() {
    return { __html: text };
  }

  return (
    <>
      <Container>
        <div>{heading}</div>
        <span dangerouslySetInnerHTML={createMarkup()} />
      </Container>
    </>
  );
};

const Container = styled.div`
  div {
    font-size: 1.5rem;
    padding-bottom: 1rem;
  }

  width: 25rem;
  color: white;
  text-align: center;
  font-size: var(--primary-font-size);
  border: 2px solid var(--primary-color);
  padding: 1rem;
`;

export default CovidBox;
