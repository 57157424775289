import React from 'react';
import styled from '@emotion/styled';
import TeamTrainingJPG from '@assets/images/team-training.jpg';
import TeamTrainingMobileJPG from '@assets/images/team-training-mobile.jpg';
import Image from '~/components/atoms/Image/Image';
import GreenBox from '~/components/atoms/GreenBox/GreenBox';

const TrainingImage: React.FC = () => {
  return (
    <>
      <Container>
        <Image
          src={TeamTrainingJPG}
          srcmobile={TeamTrainingMobileJPG}
          alt="Team im Trainingsraum"
        />
        <TeamContainer>
          <GreenBox heading="Team" text="" h1={false} />
        </TeamContainer>
      </Container>
    </>
  );
};

const Container = styled.div`
  position: relative;
  max-width: 1200px;
  height: auto;
`;

const TeamContainer = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export default TrainingImage;
